.matrix-rain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto; /* Permet l'interaction avec la souris */
    z-index: 1;
  }

  .custom-cursor::after {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, rgba(0,255,136,0.2) 0%, rgba(0,255,136,0) 70%);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  /* Ajout d'un overlay avec dégradé */
  .matrix-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 40%,
      rgba(0, 0, 0, 0.7) 60%,
      rgba(0, 0, 0, 0.9) 80%,
      #000 100%
    );
    pointer-events: none;
    z-index: 1;
  }